<template>
  <div>
    <portal :to='titleLocation'>
      <h1 class='text-2xl font-semibold px-8 py-8'>매매취소</h1>
    </portal>

    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>결제일</label>
      <span>{{ order.settlement_date }}</span>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>종목명</label>
      <span>{{ order.name }}</span>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>단축코드</label>
      <span>{{ order.share_code }}</span>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>매매구분</label>
      <span>{{ actionName(order) }}</span>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>펀드명</label>
      <select v-model='order.fund_id' class='form-select'>
        <option v-for='fund in funds' :key='`fund-${fund.id}`' v-bind:value='fund.id'>
          {{ fund.short_name }}
        </option>
      </select>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>수량</label>
      <vue-numeric
        v-model='order.quantity'
        output-type='number'
        class='form-input w-1/4 text-right'
        :minus='true'
        separator=','></vue-numeric>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>단가</label>
      <vue-numeric
        v-model='order.price'
        @change='changePrice'
        output-type='number'
        class='form-input w-1/4 text-right'
        :minus='true'
        separator=','></vue-numeric>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>금액</label>
      <vue-numeric
        v-model='order.transaction_value'
        output-type='number'
        class='form-input w-1/4 text-right'
        :minus='true'
        separator=','></vue-numeric>
    </div>

    <portal :to='actionButtonsLocation'>
      <div class='flex flex-row justify-between w-full bg-gray-100 px-8 pr-8 py-4 text-sm shadow-lg'>
        <button
          name='show-cancel-btn'
          class='p-3 text-red-700 text-sm border border-transparent hover:border-red-400 rounded-md'
          @click='cancelOrder'>거래취소 <span class='font-semibold'>{{order.name}} {{order.quantity}}주</span></button>
      </div>
    </portal>
  </div>
</template>

<script>
import OrdersApi               from '@/api/v1/orders'
import FundsApi                from '@/api/v1/funds'

export default {
  name: 'OrderEdit',
  components: {
  },
  props: [ 'order' ],
  data () {
    return {
      funds: '',
      showDelete: true,
    }
  },
  computed: {
    actionButtonsLocation () {
      return 'modal-footer'
    },
    titleLocation () {
      return 'modal-header'
    }
  },
  methods: {
    cancelOrder () {
      var cancelConfirm = confirm(this.order.name + ' 거래가 취소됩니다')
      if (!cancelConfirm) {
        return
      }
      OrdersApi.cancelOrder(this.order.id).then(resp => {
        this.doneEditing(resp)
      })
      this.showDelete = false
    },
    doneEditing (resp) {
      this.$emit('done-editing', resp)
    },
    actionName (order) {
      return order.action === 0 ? '매수취소' : '매도취소'
    },
    getFunds () {
      FundsApi.getFunds().then(resp => {
        this.funds = resp
      })
    },
    changePrice () {
      this.order.transaction_value = this.order.quantity * this.order.price
    },
  },
  mounted () {
    this.getFunds ()
  },
}
</script>
